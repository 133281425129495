<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-2">
      <b-button
        variant="primary"
        size="sm"
        @click="addAllPositions()"
        :disabled="loadingCount > 0 || positionsBusyCount > 0"
      >
        Enable all
      </b-button>

      <b-button
        variant="primary"
        size="sm"
        @click="deleteAllPositions()"
        :disabled="loadingCount > 0 || positionsBusyCount > 0"
      >
        Disable all
      </b-button>
    </div>

    <div class="flex gap-8">
     <div class="flex flex-column gap-1">
        <div>Status:</div>
        <button-select
          v-model="filters.PositionEnabled"
          :options="options.PositionEnabled"
          size="sm"
        />
      </div>

      <div class="flex flex-column gap-1">
          <div>Search:</div>
          <b-form-input
            v-model="filters.PosId"
            placeholder="Position (#########)"
            size="sm"
            autocomplete="off"
            trim
            type="search"
            debounce="500"
          ></b-form-input>
        </div>
    </div>

    <div class="font-bold">Position changes are saved automatically.</div>

    <div class="herb-positions">
      <div class="flex flex-col gap-2">
        <div v-if="filteredPositions.length === 0">
          No positions found.
        </div>

        <RecycleScroller
          class="h-100 w-[150px]"
          :items="filteredPositions"
          :item-size="40"
          key-field="PosId"
          v-slot="{ item }"
          page-mode
        >
          <div class="herb-position">
            <b-button
              :variant="getPositionVariant(item)"
              size="sm"
              class="w-100"
              :disabled="item.busy || positionsBusyCount > 0"
              @click="togglePosition(item)"
            >
              <div class="flex gap-2 justify-center relative items-center">
                <b-spinner
                  small
                  label="Busy spinner"
                  type="grow"
                  v-if="item.busy"
                  class="absolute left-0"
                ></b-spinner>
                <span>{{ item.PosId }}</span>
              </div>
            </b-button>
          </div>
        </RecycleScroller>

      </div>
    </div>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/ButtonSelect.vue');

export default {
  name: 'HerbPositions',
  components: {
    ButtonSelect,
  },
  props: {
    herbid: Number,
  },
  computed: {
    filteredPositions() {
      return this.positions.filter((position) => {
        if (this.filters.PositionEnabled !== null) {
          if (
            this.filters.PositionEnabled === true
              && !this.herbPositions.includes(position.PosId)
          ) {
            return false;
          }
          if (
            this.filters.PositionEnabled === false
            && this.herbPositions.includes(position.PosId)
          ) {
            return false;
          }
        }

        if (this.filters.PosId.length > 0 && !position.PosId.replaceAll('-', '').startsWith(this.filters.PosId)) {
          return false;
        }

        return true;
      });
    },
  },
  data() {
    return {
      loadingCount: 0,
      herbPositions: [],
      positions: [],
      positionsBusyCount: 0,
      filters: {
        PositionEnabled: null,
        PosId: '',
      },
      options: {
        PositionEnabled: [
          { value: null, text: 'All' },
          { value: true, text: 'Enabled' },
          { value: false, text: 'Disabled' },
        ],
      },
    };
  },
  methods: {
    hasPosition(position) {
      return this.herbPositions.includes(position.PosId);
    },
    getPositionVariant(position) {
      if (this.hasPosition(position)) {
        return 'primary';
      }
      return 'light';
    },
    addPosition(position) {
      const params = {
        herbid: this.herbid,
        posids: [position.PosId],
      };
      position.busy = true;
      this.loadingCount++;
      this.addPositionLocal(position);
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'positionsAdd' })
        .send({ params })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
          this.deletePositionLocal(position);
        })
        .finally(() => {
          this.loadingCount--;
          position.busy = false;
        });
    },
    deletePosition(position) {
      const params = {
        herbid: this.herbid,
        posids: [position.PosId],
      };
      position.busy = true;
      this.loadingCount++;
      this.deletePositionLocal(position);
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'positionsDel' })
        .send({ params })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
          this.addPositionLocal(position);
        })
        .finally(() => {
          this.loadingCount--;
          position.busy = false;
        });
    },
    addPositionLocal(position) {
      this.herbPositions.push(position.PosId);
    },
    deletePositionLocal(position) {
      this.herbPositions = this.herbPositions.filter((pos) => pos !== position.PosId);
    },
    addAllPositions() {
      const confirmed = confirm('Do you really wish to enable all positions?');
      if (!confirmed) {
        return;
      }
      const posids = this.positions
        .filter((position) => !this.herbPositions.includes(position.PosId))
        .map((position) => position.PosId);
      const params = {
        herbid: this.herbid,
        posids,
      };
      this.loadingCount++;
      this.positionsBusyCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'positionsAdd' })
        .send({ params })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
          this.positionsBusyCount--;
          this.fetchHerbPositions();
        });
    },
    deleteAllPositions() {
      const confirmed = confirm('Do you really wish to disable all positions?');
      if (!confirmed) {
        return;
      }
      const params = {
        herbid: this.herbid,
        posids: this.herbPositions,
      };
      this.loadingCount++;
      this.positionsBusyCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'positionsDel' })
        .send({ params })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
          this.positionsBusyCount--;
          this.fetchHerbPositions();
        });
    },
    togglePosition(position) {
      if (position.busy) {
        return;
      }
      if (this.hasPosition(position)) {
        this.deletePosition(position);
      } else {
        this.addPosition(position);
      }
    },
    fetchHerbPositions() {
      if (!this.herbid) {
        return;
      }
      const params = {
        herbid: this.herbid,
      };
      this.loadingCount++;
      this.positionsBusyCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'positions' })
        .send({ params })
        .then((res) => {
          this.herbPositions = res.body.map((position) => position.PosId);
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
          this.positionsBusyCount--;
        });
    },
    fetchPositions() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'sana.herb' })
        .send({ method: 'positionsItems' })
        .then((res) => {
          this.positions = res.body.map((position) => {
            position.busy = false;
            return position;
          });
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchHerbPositions();
      this.fetchPositions();
    },
  },
  created() {
    this.fetchAll();
  },
};
</script>

<style lang="scss" scoped>
  .herb-positions {
    width: 150px;
  }

  .herb-position {
    display: flex;
    align-items: center;
    height: 31px;
  }
</style>
